<template>
  <div class="machine-operation-status">
    <ul class="machine-list">
      <li 
        class="machine"
        v-for="m, i in process_list"
        :key="'machine' + m.mid + '-' + i"
      >
      <div class="machine__status">
        <p class="machine-key">{{ m.key }}</p>
        <p class="machine-big-icon" :class="m.execution">
          <template v-if="m.execution == 'ACTIVE'">작동중</template>
          <template v-else-if="m.execution == 'STOPPED'">일시정지</template>
          <template v-else-if="m.execution == 'POWER_OFF'">전원 꺼짐</template>
          <template v-else>대기중</template>
        </p>
      </div>
      <div class="machine__content">
        <p class="mid">{{ m.mid }}</p>
        <p class="machine-label program">
          <span class="label-txt">공 정 명</span>
          <span class="machine-value">{{ programName(m.process) }}</span>
        </p>
        <p class="machine-label quantity">
          <span class="label-txt">수 량</span>
          <span class="machine-value" v-if="m.plan_count">{{ m.part_count }}/{{ m.plan_count }}({{ getPercent(m.part_count, m.plan_count) }})</span>
        </p>
        <p class="machine-label due">
          <span class="label-txt">완 료 예 정</span>
          <span class="machine-value">{{ dateFormatter(m.lot_endtime) }}</span>
        </p>
      </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      process_list:[],
      machine_data: [
        {
          key: 1,
          mid: 'Lynx220LSY12',
          program: '900131-00145-02',
          part_count: 123,
          plan_count: 1000,
          exeuction: 'ACTIVE',
          due: '2021-09-30 11:30',
        },
        {
          key: 2,
          mid: 'Puma280',
          program: '900131-600104-02-8351512',
          part_count: 81,
          plan_count: 200,
          exeuction: 'STOPPED',
          due: '2021-09-10 11:30',
        },
        {
          key: 1,
          mid: 'Lynx220LSY12',
          program: '900131-00145-02',
          part_count: 123,
          plan_count: 1000,
          exeuction: 'ACTIVE',
          due: '2021-09-30 11:30',
        },
        {
          key: 2,
          mid: 'Puma280',
          program: '900131-600104-02',
          part_count: 81,
          plan_count: 200,
          exeuction: 'STOPPED',
          due: '2021-09-10 11:30',
        },
        {
          key: 1,
          mid: 'Lynx220LSY12',
          program: '900131-00145-02',
          part_count: 123,
          plan_count: 1000,
          exeuction: 'ACTIVE',
          due: '2021-09-30 11:30',
        },
        {
          key: 2,
          mid: 'Puma280',
          program: '900131-600104-02',
          part_count: 81,
          plan_count: 200,
          exeuction: 'STOPPED',
          due: '2021-09-10 11:30',
        },
        {
          key: 1,
          mid: 'Lynx220LSY12',
          program: '900131-00145-02',
          part_count: 123,
          plan_count: 1000,
          exeuction: 'ACTIVE',
          due: '2021-09-30 11:30',
        },
        {
          key: 2,
          mid: 'Puma280',
          program: '900131-600104-02',
          part_count: 81,
          plan_count: 200,
          exeuction: 'STOPPED',
          due: '2021-09-10 11:30',
        },
      ]
    };
  },
  methods: {
    getPercent(val, to) {
      var percent = to == 0 ? null : ((val / to) * 100).toFixed(2)+'%' ;
      return percent ;
    },
    dateFormatter(v){
      if(v){
        v = v.substr(0,19).replace('T',' ');
      }
      return v;
    },
    programName(value){
      if(value != undefined){
        let char
        if(value.indexOf('(') != -1){
          char = value.split('(')[1];
          char = char.replace(')', '');
        }else{
          char = value ;
        }
        return char;
      }

    },
  },
  mounted(){
    this.$cnc.setCncList(this.process_list);
    this.$cnc.start();
    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti');
    this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
  },
  watch:{
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

  .machine-operation-status {

    .machine-list {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(20%, auto));
      gap: 16px;
      padding: 45px 64px;
      list-style: none !important;

      @include relative-web1 {
        grid-template-columns: repeat(auto-fit, minmax(25%, auto));
      }
      @include relative-web1-1 {
        grid-template-columns: repeat(auto-fit, minmax(30%, auto));
      }
      @include relative-web2-1 {
        grid-template-columns: repeat(auto-fit, minmax(45%, auto));
        // grid-template-columns: 1fr;
      }
      @include relative-custom(1024) {
        grid-template-columns: 1fr;
      }

      .machine {
        @extend .flex_row;

        justify-content: stretch;
        align-items: flex-end;
        padding: 27px 30px;
        border-radius: 8px;
        background-color: #f6f6f6;

        &__status {
          @extend .flex_column;

          flex: 0 0 auto;
          justify-content: stretch;
          height: 95px;
          margin-right: 20px;
          text-align: center;
        }
        &-key {
          flex: 0 0 auto;
          font-size: 23px;
          font-weight: 300;
          line-height: 0.8;
          padding-top: 0.3vw;
        }
        &-big-icon {
          flex: 1 1 0;
          width: 90px;
          overflow: hidden;
          text-indent: -999999px;
          background: url(svgs(machine, 6ebd33)) center bottom no-repeat;
          background-size: 100% auto;

          &.STOPPED { background-image: url(svgs(machine, f5b117));}
          &.POWER_OFF { background-image: url(svgs(machine, c8c8c8));}
        }

        &__content {
          @extend .flex_column;

          flex: 1 1 auto;
          justify-content: flex-end;
          height: 100%;
        }
        .mid {
          width: 100%;
          font-size: 23px;
          font-weight: 600;
          justify-self: flex-start;
          margin-bottom: auto;
        }
        &-label {
          @extend .flex_row;

          justify-content: stretch;
          align-items: flex-start;
          width: 100%;
          font-size: 14px;
          white-space: nowrap;
          margin-bottom: 3px;
          font-weight: 400;

          &:last-child {
            margin-bottom: 0;
          }

          .label-txt {
            flex: 0 0 70px;
            display: inline-block;
            font-weight: 500;
            margin-right: 8px;
            text-align: justify;
            text-align-last: justify;
          }
        }
        &-value {
          flex: 1 1 0;
          white-space: break-spaces;
          word-break: break-all;
        }
      }
    }
  }
</style>>

</style>